import { useEffect } from 'react';

import { Montserrat } from '@next/font/google';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { PageProps } from '@hultafors/wibe/types';

export { reportWebVitals } from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/wibe/context').then((module) => module.AppProvider),
);
const Footer = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Footer),
);

const GlobalStyle = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.GlobalStyle,
  ),
);

const MarketSelectorQR = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.MarketSelectorQR,
  ),
);

const Nav = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Nav),
);

const TimedDialog = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.TimedDialog,
  ),
);

const fontMontserrat = Montserrat({
  weight: ['100', '300', '400', '500', '800'],
  subsets: ['latin'],
});

// TODO: Set types for page props
function CustomApp({ Component, pageProps, router }: AppProps<PageProps>) {
  const {
    global,
    menu,
    allMenuItems,
    maintenanceMode,
    timedDialog,
    footer,
    languageSelector,
    settings,
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <style jsx global>
        {`
          html,
          input,
          textarea,
          button,
          select {
            font-family: ${fontMontserrat.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <AppProvider
        value={{
          global,
          menu,
          allMenuItems,
          maintenanceMode,
          timedDialog,
          footer,
          languageSelector,
          settings,
        }}
      >
        <GlobalStyle />
        <Nav />
        <Component {...pageProps} />
        <GoogleTagManager gtmId="GTM-K7TXDV" />
        <Footer />
        <MarketSelectorQR />
        {timedDialog?.formUrl && <TimedDialog timedDialogData={timedDialog} />}
        <div id="portal"></div>
      </AppProvider>
    </>
  );
}

export default CustomApp;
